import React from 'react'
import './Carousel.css'

const Carousel = () => {
  return (
    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div className='row mx-0 align-items-center '>
            <div className='col-lg-6 d-flex justify-content-left flex-column slide'>
              <h1 className='text-start'>Best Game Development Company</h1>
              <p className='text-start'>Our aim is to make next- gen top performing mobile games for which our ambitious and highly skilled team is always working on exciting new technologies to bring amazing gaming experience. Therefore, we are committed to continuously strengthening our brands and games to improve our competitive position. No matter whether you are a newbie casual gamer or hardcore gamer, we want to engage with you in a long – lasting relationship.</p>
              <button type="button" className="btn main-btn mr-auto btn-warning">Explore More</button>
            </div>
            <div className='col-lg-6'>
              <img alt='yo' className='img-style' src={'./images/carousel-1.png'} />
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div className='row mx-0 align-items-center '>
            <div className='col-lg-6 d-flex justify-content-left flex-column slide'>
              <h1 className='text-start'>Best Game Development Company</h1>
              <p className='text-start'>Our aim is to make next- gen top performing mobile games for which our ambitious and highly skilled team is always working on exciting new technologies to bring amazing gaming experience. Therefore, we are committed to continuously strengthening our brands and games to improve our competitive position. No matter whether you are a newbie casual gamer or hardcore gamer, we want to engage with you in a long – lasting relationship.</p>
              <button type="button" className="btn main-btn mr-auto btn-warning">Explore More</button>
            </div>
            <div className='col-lg-6'>
              <img alt='yo' className='img-style' src={'./images/carousel-2.png'} />
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default Carousel