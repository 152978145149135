import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

const Navbar = () => {
  return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light nav-style">
            <div className="container-fluid px-0">
                <img alt='logo' src={'./images/logo-interbolt.png'}/>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul  className="navbar-nav ml-auto mb-2 mb-lg-0 ul-style">
                    <li className="nav-item">
                        <Link to={'/'} className="nav-link active" aria-current="page">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/privacy'} className='nav-link active'>Privacy Policy</Link> 
                    </li>
                    <li className="nav-item">
                        <Link to={'/privacy'}className="nav-link active" aria-current="page">Terms and Condition</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/privacy'} className="nav-link active" aria-current="page">Contact</Link>
                    </li>
                </ul>
                </div>
            </div>
        </nav>
  )
}

export default Navbar