import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <div id='footer'>
            <footer className="row mx-0 row mx-0-cols-5 py-5 my-5 border-top">
                <div className="col left-sec">
                    <a href="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none">
                        <img alt='logo' src='https://www.interboltgames.com/wp-content/uploads/2020/03/logo-hosting1111.png' />
                    </a>
                    <p className="text-muted">The doors of InterBolt opened in 2017, a company of 30+ passionate individuals laser focused on combining their creative background to produce an exceptional content for a global audience.</p>
                </div>
                <div className="col"></div>
                <div className="col">
                    <h5>Support</h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="2" className="nav-link p-0 text-muted">Home</a></li>
                        <li className="nav-item mb-2"><a href="2" className="nav-link p-0 text-muted">Privacy Policy</a></li>
                        <li className="nav-item mb-2"><a href="2" className="nav-link p-0 text-muted">Terms & Condition</a></li>
                        <li className="nav-item mb-2"><a href="2" className="nav-link p-0 text-muted">Contact</a></li>
                    </ul>
                </div>
                <div className="col">
                    <h5>Address</h5>
                    <ul className="social-links nav flex-column">
                        <li className="nav-item mb-2"><a href="3" className="nav-link p-0 text-muted">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg> Email: Interboltgames@Gmail.Com</a>
                        </li>
                    </ul>
                </div>
                {/* <div className="footer-copyright text-center py-3">Copyright © 2020 By CherryByte. All Rights Reserved.
                </div> */}
            </footer>
        </div>
    )
}

export default Footer
