import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import AboutUs from './components/AboutUs/AboutUs';
import { Main } from './components/Main/Main';
import Footer from './components/Footer/Footer';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import PrivacyPoilicy from './components/Privacy Policy/PrivacyPoilicy';
import Carousel from './components/Carousel/Carousel';
import Home from './components/Home/Home';


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home/>} exact />
          <Route path="/privacy" element={<PrivacyPoilicy />} exact />
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
