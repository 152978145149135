import React from 'react'
import './Main.css'

export const Main = () => {
  return (
    <div id='main'>
      <div className='row mx-0 align-items-center main-sec'>
        <div className='col-lg-6 d-flex justify-content-left flex-column'>
          <h1>Police Flying Rhino Robot Transform Robot Shooting</h1>
          <p>Grand robot battle is the best mech warrior and robot transforming games that has been started between the futuristic rhino robot and flying rhino robot transform: robot games to claim the rule of city. Participate in futuristic robot battle to become mech warrior in Wild Rhino Robot Transform Game. Flying Rhino robot transform or robot transforming games are famous in all over the world. You might have played multiple robot transformation games such as lion robot transform, ultimate robot fighting, futuristic rhino, cars and robots but I am sure you never played flying rhino robot transforming game. The futuristic rhino robot game is transforming robot war and mech robot battle game that show you robotic fight between evil robots and grand robots. You are an ultimate real steel robot warrior and shoot the bad robots.</p>
          <button type="button" className="btn main-btn mr-auto btn-warning">Explore More</button>
        </div>
        <div className='col-lg-6'>
          <img alt='yo' className='img-style' src={'./images/main-1.png'} />
        </div>
      </div>
      <div className='row mx-0 align-items-center main-sec'>
        <div className='col-lg-6 d-flex justify-content-left flex-column'>
          <img alt='yo' className='img-style' src={'./images/main-2.png'} />
        </div>
        <div className='col-lg-6 d-flex justify-content-left flex-column'>
          <h1>Formula Car GT Racing Stunts- Impossible Tracks</h1>
          <p>Get ready to play impossible car games ramp drive in which you will drive multiple cars like extreme racing stunts car. Go and enjoy this mega ramp car stunts driving free gameplay and come to be a professional trick master of car stunts games. Travel on challenging tracks with top speed formula car parking stunts games. These ultimate formula car games 2019 multi stunts drive is adventurous creation for car stunt games lovers. Download this extreme gt car racing stunts 2019 & practice the liveliest mega ramp formula racing games 2019. Different formula race vehicles selections of this formula car stunt games will amuse you in the sea water climate. Take test drive of ramp car impossible ramp simulator over sea view and enjoy car driving in sky ramps with your selected formula car challenge .</p>
          <button type="button" className="btn main-btn mr-auto btn-warning">Explore More</button>
        </div>
      </div>
      <div className='row mx-0 align-items-center main-sec'>
        <div className='col-lg-6 d-flex justify-content-left flex-column'>
          <h1>Atv Quad Bike Stunt Racing: Impossible Tracks 3D</h1>
          <p>Get ready to play impossible car games ramp drive in which you will drive multiple cars like extreme racing stunts car. Go and enjoy this mega ramp car stunts driving free gameplay and come to be a professional trick master of car stunts games. Travel on challenging tracks with top speed formula car parking stunts games. These ultimate formula car games 2019 multi stunts drive is adventurous creation for car stunt games lovers. Download this extreme gt car racing stunts 2019 & practice the liveliest mega ramp formula racing games 2019. Different formula race vehicles selections of this formula car stunt games will amuse you in the sea water climate. Take test drive of ramp car impossible ramp simulator over sea view and enjoy car driving in sky ramps with your selected formula car challenge .</p>
          <button type="button" className="btn main-btn mr-auto btn-warning">Explore More</button>
        </div>
        <div className='col-lg-6'>
          <img alt='yo' className='img-style' src={'./images/main-3.png'} />
        </div>
      </div>
      <div className='row mx-0 align-items-center main-sec'>
        <div className='col-lg-6'>
          <img alt='yo' className='img-style' src={'./images/main-4.png'} />
        </div>
        <div className='col-lg-6 d-flex justify-content-left flex-column'>
          <h1>Counter Terrorist Gun Strike FPS Shooting Games</h1>
          <p>Experience the new special ops gun strike game with counter shooting and destroy enemies with fps shoot combat strike. Go and enter into opponent army area to complete the challenges. Play free gun games free to clear the area in this counter terrorist game: fps fight game and get victory in all missions. A remarkable mobile fps shooting game where you will have to finish enemies with your special ops. Enjoy fps gun shooting strike combat war and accomplish target with your best fps shooting games practices.</p>
          <button type="button" className="btn main-btn mr-auto btn-warning">Explore More</button>
        </div>
      </div>
    </div>
  )
}
