import React from 'react'
import AboutUs from '../AboutUs/AboutUs'
import Carousel from '../Carousel/Carousel'
import { Main } from '../Main/Main'

const Home = () => {
  return (
    <div>
        <Carousel/>
        <AboutUs/>
        <Main/>
    </div>
  )
}

export default Home